



















































































import { debounce } from "@/helpers/debounce";
import { Messages } from "@/models/enums/messages.enum";
import { ResponseDetailUserByAuth } from "@/models/interface/user.interface";
import { userServices } from "@/services/user.services";
import { checkPasswordStrength } from "@/validator/globalvalidator";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      newPassword: "" as string,
      userId: "" as string,
      isFormSubmitted: false,
      formChangePassword: this.$form.createForm(this, {
        name: "changePassword",
      }),
      formRulesChangePassword: {
        oldPassword: {
          label: "lbl_current_password",
          name: "oldPassword",
          placeholder: "lbl_current_password_placeholder",
          decorator: [
            "oldPassword",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        newPassword: {
          label: "lbl_new_password",
          name: "newPassword",
          placeholder: "lbl_new_password_placeholder",
          decorator: [
            "newPassword",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                {
                  validator: checkPasswordStrength,
                },
              ],
            },
          ],
        },
        confirmPassword: {
          label: "lbl_confirm_password",
          name: "confirmPassword",
          placeholder: "lbl_confirm_password_placeholder",
          decorator: [
            "confirmPassword",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                {
                  validator: this.customValidator,
                },
              ],
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.getUserDetailByAuth();
  },
  methods: {
    submitForm(e: Event): void {
      e.preventDefault();
      this.formChangePassword.validateFields((err, values) => {
        if (err || this.isFormSubmitted) return;
        this.isFormSubmitted = true;
        const payload = {
          userId: this.userId,
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        };
        debounce(() => {
          userServices
            .changePassword(payload)
            .then(value => {
              if (value) {
                this.$notification.success({
                  description: Messages.CHANGE_PASSWORD_SUCCESS,
                  message: "Success",
                  duration: 30,
                });
                this.formChangePassword.resetFields();
              }
            })
            .catch(() => (this.isFormSubmitted = false))
            .finally(() => (this.isFormSubmitted = false));
        }, 500);
      });
    },
    customValidator(_rule, value, callback) {
      if (this.newPassword !== value) {
        callback("Password doesn't match");
      } else {
        callback();
      }
    },
    getUserDetailByAuth() {
      userServices.userDetailAuth().then((value: ResponseDetailUserByAuth) => {
        this.userId = value.id;
      });
    },
  },
});
